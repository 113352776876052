/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, PriceWrap, Text, Button, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column style={{"paddingTop":2,"paddingBottom":3,"backgroundColor":"rgba(0, 0, 0, 1)"}} name={"6nfsw4g8rpa"}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" style={{"paddingTop":0,"marginBottom":31,"paddingBottom":0}} content={"Ceník"}>
              </Title>

              <PriceWrap className="el--2" style={{"marginTop":0,"paddingTop":0,"paddingBottom":0,"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper style={{"paddingBottom":0}}>
                  
                  <Text className="text-box fs--18" style={{"marginBottom":0,"paddingBottom":2}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Mytí vlasů a foukaná s krátkými vlasy</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 30 min.</span><br>Služba obsahuje: Diagnostiku vlasů, mytí vlasů, základní ošetření, foukaná vlasů, konečný styling.\n </span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right" style={{"maxWidth":646}}>
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">500,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Mytí vlasů a foukaná s dlouhými vlasy</span><br>&nbsp;<span style=\"font-style: italic;\">Doba trvání procedury je 45 min.</span><br>&nbsp;Služba obsahuje: Diagnostiku vlasů, mytí vlasů, základní ošetření, foukaná vlasů, konečný styling.\n</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">700,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Dámský střih krátkých vlasů</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 60 min.</span><br>Služba obsahuje: Diagnostiku vlasů, střih vlasů, základní ošetření, foukaná vlasů, konečný styling.\n</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">1000,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Dámský střih dlouhých vlasů</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 90 min.</span><br>Služba obsahuje: Diagnostiku vlasů, střih vlasů, základní ošetření, foukaná vlasů, konečný styling.\n</span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">1400,— Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Barvení vlasů\n</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 120 min.&nbsp;</span><br>Služba je zaměřena na barvení odrostů, barvení celých vlasů, tónování vlasů\n<br>Služba obsahuje: Diagnostiku vlasů, barvení vlasů, základní střih, základní ošetření, foukání vlasů, konečný styling.\nCena služby se může lišit dle délky, spotřebovaného materiálu a hustoty vlasů.\n </span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">Krátké vlasy: od 2.500,</span><span style=\"color: var(--color-blend--95);\">—</span><span style=\"color: var(--black);\">Kč Dlouhé vlasy: od 2.800 ,—Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Barvení vlasů</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 180 min.</span><br>Služba je zaměřena na barvení celých vlasů, lehké zesvětlení, barvení tón v tónu\n<br>Služba obsahuje: Diagnostiku vlasů, barvení vlasů, základní střih, základní ošetření, foukání vlasů, konečný styling.\nCena služby se může lišit dle délky, spotřebovaného materiálu a hustoty vlasů.\n </span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">Krátké vlasy: od 3.300</span><span style=\"color: var(--color-blend--95);\">,—</span><span style=\"color: var(--black);\">Kč Dlouhé vlasy: od 3.600,—Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold;\">Barvení vlasů</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 240 min.</span><br>Služba je zaměřena na kompletní proměnu vlasů, při které docílíme maximálního výsledku už při jedné návštěvě salonu. (Balayage, foilyage, airtouch, kombinované barvení a tónování vlasů)\n<br>Služba obsahuje: Diagnostiku vlasů, barvení vlasů, základní střih, základní ošetření, foukání vlasů, konečný styling.\nCena služby se může lišit dle délky, spotřebovaného materiálu a hustoty vlasů.\n </span>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">Krátké vlasy: od 4.600,</span><span style=\"color: var(--color-blend--95);\">—Kč</span><span style=\"color: var(--black);\">&nbsp;Dlouhé vlasy: od 4.800,</span><span style=\"color: var(--color-blend--95);\">—Kč</span><span style=\"color: var(--black);\"><br></span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--95);\"><span style=\"font-weight: bold;\">Náročné barvení vlasů</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 300 min. </span><br>Služba je zaměřena na kompletní proměnu dlouhých a hustých vlasů, při které docílíme maximálního výsledku už při jedné návštěvě salonu. Služba obsahuje: Diagnostiku vlasů, barvení vlasů, základní střih, základní ošetření, foukání vlasů, konečný styling. Cena služby se může lišit dle délky, spotřebovaného materiálu a hustoty vlasů.</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--black);\">Krátké vlasy: od 5.900,</span><span style=\"color: var(--color-blend--95);\">—</span><span style=\"color: var(--black);\">Kč Dlouhé vlasy: od 6.500,</span><span style=\"color: var(--color-blend--95);\">—</span><span style=\"color: var(--black);\">Kč</span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--color-blend--95);\">Rituál Kérastase Paris - Expresní službou Fusio Dose\n</span><br><span style=\"color: var(--color-blend--95);\"><span style=\"font-style: italic;\">Doba trvání procedury je 15 min.\n</span><br>Služba je zaměřena na okamžitou proměnu vlasů vhodná před foukanou a po barvení vlasů, která je určena pro intenzivní výživu a regeneraci. Služba obsahuje: Hloubkové ošetření vlasů rituálem Kérastase Paris.</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">549,— Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"font-weight: bold; color: var(--color-blend--95);\">Foukaná krátkých vlasů a ošetření s fusio dose </span><br><span style=\"color: var(--color-blend--95); font-style: italic;\">Doba trvání procedury je 90 min.&nbsp;</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">1049,— Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--95);\"><span style=\"font-weight: bold;\">Foukaná dlouhých vlasů a ošetření s fusio dose&nbsp;</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 90 min.</span></span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">1249,— Kč<br></span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--95);\"><span style=\"font-weight: bold;\">Metal detox</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 30 min.</span><br>Služba je zaměřena na pročištění vlasů od kovových částic, které se ve vlasech usazují v závislosti na kvalitě vody. Je vhodná pro všechny tipy vlasů, před barvením, která neutralizuje kovy uvnitř vlasu. Vlasy mají po použití dlouhotrvající barevné výsledky, menší lámavost a intenzivní lesk vlasů. Služba obsahuje: 4 fáze: Metal detox sprej, Metal detox šampon, Metal detox krém nebo olej. Doporučení produktů pro správné ošetřování vlasů pro domácí peči. Služba je vhodná v den barvení, zesvětlování a tónování vlasů.&nbsp;</span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">450,— Kč<br></span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--95);\"><span style=\"font-weight: bold;\">Foukaná krátkých vlasů a ošetření s metal detox</span><br><span style=\"font-style: italic;\">Doba trvání procedury je 90 min.</span></span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">949,— Kč<br></span>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="el--2" style={{"backgroundColor":"#f4f4f4"}} columns={"2"}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--18" content={"<span style=\"color: var(--color-blend--95);\"><span style=\"font-weight: bold;\">Foukaná dlouhých vlasů a ošetření s metal detox<br></span><span style=\"font-style: italic;\">Doba trvání procedury je 90 min.</span></span><br>"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">&nbsp;1149,— Kč</span><br>"}>
                  </Text>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"backgroundColor":"var(--color-blend--95)","paddingTop":19,"paddingBottom":39}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn5 fs--16 swpf--uppercase" url={"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services"} href={"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services"} content={"CHCI SE OBJEDNAT"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 pl--40 pr--40 flex--bottom" style={{"marginTop":0}} columns={"4"}>
            
            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Adresa<br>"}>
              </Title>

              <Text className="text-box" content={"STUDIO Petra Nováková<br>Vladislavova 332, Kutná Hora&nbsp;<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"paddingLeft":0}}>
              
              <Image style={{"maxWidth":163}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/36990/72d2e55d7c8a426d98534087c18e3c7a_s=350x_.jpg 350w"} alt={""} src={"https://cdn.swbpg.com/t/36990/72d2e55d7c8a426d98534087c18e3c7a_s=350x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"paddingRight":0}}>
              
              <Image style={{"maxWidth":157}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/36990/68d551178fac4cacafc50ccf2843ae0b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/68d551178fac4cacafc50ccf2843ae0b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/68d551178fac4cacafc50ccf2843ae0b_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/36990/68d551178fac4cacafc50ccf2843ae0b_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+420 605 338 117<br>info@studioloreal.cz<br>www.studioloreal.cz&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":144}} alt={""} src={"https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}